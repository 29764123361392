<template>
  <b-sidebar
    v-if="isSidebarActive"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Email Template Detail
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="EmailTemplateDetailForm">
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="emailTemplate.templateName"
            name="Template Name"
            label="Template Name"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="emailTemplate.subject"
            name="Subject"
            label="Subject"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="emailTemplate.message"
            name="Message"
            label="Message"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="emailTemplate.remark"
            name="Remark"
            label="Remark"
            :rules="{ required: false }"
            trim />
          <SelectField
            v-model="emailTemplate.emailGroupId"
            :options="emailGroupName"
            :rules="{ required: false }"
            name="Email Group Id"
            form-label="Email Group ID"
            label-option="groupName"
            return-value="id" />
          <StatusRadioButton :status.sync="emailTemplate.status" />
          <b-row>
            <b-col>
              Created At : {{ emailTemplate.createdAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Created By : {{ emailTemplate.createdBy.username }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated At : {{ emailTemplate.updatedAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated By : {{ emailTemplate.updatedBy.username }}
            </b-col>
          </b-row>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit">
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon ml-auto"
              :disabled="!compareOldValue"
              @click="deleteItem()">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import StatusRadioButton from '@/components/Form/StatusRadioButton.vue'

export default {
  components: {
    TextFieldInput,
    SelectField,
    StatusRadioButton
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    initialEmailTemplate: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      required: true
    },
    emailGroupName: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    emailTemplate: {
      get () {
        return this.selectedItem
      },
      set (val) {
        this.$emit('update:selected-item', val)
      }
    },
    compareOldValue () {
      return JSON.stringify(this.emailTemplate) === JSON.stringify(this.initialEmailTemplate)
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      const payload = {
        id: this.emailTemplate.id,
        templateName: this.emailTemplate.templateName,
        subject: this.emailTemplate.subject,
        message: this.emailTemplate.message,
        remark: this.emailTemplate.remark,
        emailGroupId: this.emailTemplate.emailGroupId,
        status: this.emailTemplate.status
      }
      this.$emit('update:email-template', payload)
    },
    resetForm () {
      this.emailTemplate = { ...this.initialEmailTemplate }
      this.$refs.EmailTemplateDetailForm.reset()
    },
    deleteItem () {
      this.$emit('delete-item', {
        ID: this.emailTemplate.id,
        Name: this.emailTemplate.templateName
      })
    }
  }
}
</script>

<style>

</style>
