<template>
  <div v-if="isLoaded">
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <!-- <EmailTemplateAdd
      ref="emailTemplateAdd"
      :email-group="emailGroupName"
      :is-sidebar-active.sync="isSidebarActive"
      @submit="addEmailTemplate($event)" /> -->
    <EmailTemplateDetail
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-email-template.sync="initialEmailTemplate"
      :selected-item.sync="selectedItem"
      :email-group-name="emailGroupName"
      @update:email-template="updateEmailTemplate($event)"
      @delete-item="openDeleteModal($event)" />
    <EmailTemplateFillter
      :filter.sync="filter"
      :email-group="emailGroupName"
      :status-filter.sync="filter.status"
      @update:search-email-template="filter.searchTemplateName=$event"
      @update:search-email-subject="filter.searchSubject=$event"
      @update:search-email-group="filter.searchEmailGroup=$event"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Email Template"
        to-path="/email-template/add"
        @change="fetchFilter()" />
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
        @sort-changed="sortFilter($event)"
        @row-clicked="rowClick($event)">
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
        <template #cell(emailGroup)="{ item }">
          <div v-if="item && item.emailGroup && item.emailGroup.groupName">
            {{ item.emailGroup.groupName }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(templateName)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
        <template #cell(remark)="data">
          <div v-if="data && data.item && data.item.remark">
            {{ data.item.remark }}
          </div>
          <div v-else>
            -
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import EmailTemplateFillter from '@/components/New/EmailSetting/EmailTemplate/EmailTemplateFillter.vue'
// import EmailTemplateAdd from '@/components/New/EmailSetting/EmailTemplate/EmailTemplateAdd.vue'
import EmailTemplateDetail from '@/components/New/EmailSetting/EmailTemplate/EmailTemplateDetail.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import TableTop from '@/components/TableTop.vue'
import EmailTemplateProvider from '@/resources/EmailTemplateProvider'
import EmailGroupProvider from '@/resources/EmailGroupProvider'

const EmailTemplateService = new EmailTemplateProvider()
const EmailGroupService = new EmailGroupProvider()

export default {
  components: {
    EmailTemplateFillter,
    // EmailTemplateAdd,
    EmailTemplateDetail,
    DeleteModal,
    TableTop
  },
  data () {
    return {
      isLoaded: false,
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        searchTemplateName: '',
        searchSubject: '',
        searchEmailGroup: null,
        status: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 0
      },
      fields: [
        { key: 'templateName', label: 'Template Name', sortable: true },
        { key: 'subject', label: 'Subject' },
        { key: 'message', label: 'Message' },
        // { key: 'remark', label: 'Remark' },
        // { key: 'emailGroup', label: 'Email Group', sortable: true },
        { key: 'status', label: 'Status' }
      ],
      items: [],
      selectedItem: {},
      deleteItem: {},
      initialEmailTemplate: {},
      emailGroupName: []
    }
  },
  async created () {
    try {
      await this.getEmailTemplate()
      await this.getEmailGroup()
    } finally {
      this.isLoaded = true
    }
  },
  methods: {
    async getEmailTemplate () {
      try {
        const { searchEmailGroup } = this.filter
        const page = this.$route?.query?.page || this.pagination?.page || 1
        const limit = this.$route?.query?.limit || this.pagination?.limit || 10
        const data = await EmailTemplateService.paginate(
          page,
          limit,
          {
            searchTemplateName: this.filter.searchTemplateName,
            searchSubject: this.filter.searchSubject,
            searchEmailGroup,
            status: this.filter.status
          }
        )
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async getEmailGroup () {
      try {
        const { data } = await EmailGroupService.findAll()
        this.emailGroupName = data
      } catch (error) {
        console.log(error)
      }
    },
    // async addEmailTemplate (val) {
    //   try {
    //     await EmailTemplateService.create(val)
    //     await this.getEmailTemplate()
    //     this.resetFormAndToggleSideBar(this.$refs.emailTemplateAdd)
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Add Record Successfully',
    //         icon: 'EditIcon',
    //         variant: 'success',
    //         text: '👋 Email Template has been added'
    //       }
    //     })
    //   } catch (err) {
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Add Fail',
    //         icon: 'EditIcon',
    //         variant: 'danger',
    //         text: `😞 Email Template has been Failed ${ err}`
    //       }
    //     })
    //   }
    // },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    fetchFilter () {
      this.setPagination(1)
      this.getEmailTemplate()
    },
    activeSidebar () {
      this.isSidebarActive = true
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getEmailTemplate()
    },
    setPagination (page, totalDocs) {
      this.$router.replace(`email-template?page=${page}`)
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
      this.pagination.page = page || this.pagination.page
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await EmailTemplateService.softDelete(val.ID)
        await this.getEmailTemplate()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Email Template has been deleted'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Email Template has been Failed ${error}`
          }
        })
      }
    },
    async updateEmailTemplate (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await EmailTemplateService.update(val.id, payload)
        await this.getEmailTemplate()
        this.setInitialEmailTemplateAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Email Template has been updated'
          }
        })
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Email Template has been Failed'
          }
        })
      }
    },
    sortFilter (ctx) {
      console.log(ctx)
    },
    rowClick (val) {
      this.$router.push({ name: 'email-template-detail', params: { id: val.id } })
    },
    setInitialEmailTemplateAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialEmailTemplate = { ...val }
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
</style>
