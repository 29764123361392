import HttpRequest from './HttpRequest'

class EmailGroupProvider extends HttpRequest {
  findAll () {
    return this.get('/email-group')
  }

  findOne (id) {
    return this.get(`/email-group/${id}`)
  }

  create (payload) {
    return this.post('/email-group', payload)
  }

  update (id, payload) {
    return this.patch(`/email-group/${id}`, payload)
  }

  softDelete (id) {
    return this.delete(`/email-group/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/email-group?page=${page}&limit=${limit}`, query)
  }
}

export default EmailGroupProvider
