<template>
  <b-row class="align-items-end">
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <SearchTextInput
        v-model="searchEmailTemplate"
        label="Email Template"
        placeholder="Email Template"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <SearchTextInput
        v-model="searchEmailSubject"
        label="Email Subject"
        placeholder="Email Subject"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <FilterDropdown
        :email.sync="searchEmailGroup"
        :options="emailGroup"
        label="Email Group"
        placeholder="Email Group"
        label-option="groupName"
        return-value="id"
        @input="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <StatusDropdown
        :status-filter.sync="statusFilterValue"
        @change-status-filter="fetchFilter()"
      />
    </b-col>
    <b-col
      sm="0"
      md="9"></b-col>
    <b-col
      sm="12"
      md="3"
      class="mt-1">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="w-100"
        variant="primary"
        @click="fetchFilter()">
        <feather-icon
          icon="SearchIcon"
          class="mr-50 mr-md-0 mr-lg-0 mr-xl-50" />
        <span class="align-middle">Search</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
// import SelectField from '@/components/Form/SelectField.vue'
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import FilterDropdown from '@/components/Filter/FilterDropdown.vue'
import StatusDropdown from '@/components/Filter/StatusDropdown.vue'

export default {
  components: {
    StatusDropdown,
    SearchTextInput,
    FilterDropdown

  },
  props: {
    statusFilter: {
      type: String,
      default: null
    },
    filter: {
      type: Object,
      default: () => (
        {
          searchTemplateName: '',
          searchSubject: '',
          searchEmailGroup: []
        }
      )
    },
    emailGroup: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      email: []
    }
  },
  computed: {
    searchEmailGroup: {
      get () {
        return this.filter.searchEmailGroup
      },
      set (val) {
        this.$emit('update:search-email-group', val)
      }
    },
    searchEmailSubject: {
      get () {
        return this.filter.searchSubject
      },
      set (val) {
        this.$emit('update:search-email-subject', val)
      }
    },
    searchEmailTemplate: {
      get () {
        return this.filter.searchTemplateName
      },
      set (val) {
        this.$emit('update:search-email-template', val)
      }
    },
    statusFilterValue: {
      get () {
        return this.statusFilter
      },
      set (val) {
        this.$emit('update:status-filter', val)
      }
    }
  },
  methods: {
    fetchFilter () {
      this.$emit('fetch-filter')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
